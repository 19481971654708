import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { client2 } from '../../operationalRequest';
import _ from 'lodash';

type CustomSelectOptionsProps = {
  placeholder?: string;
  onChange?: any;
  value?: any;
  options: any;
  disabled?: boolean;
  multiple?: boolean
};

const CustomSelectOptions: React.FC<CustomSelectOptionsProps> = ({ onChange, placeholder = 'Select', value, options, disabled = false, multiple }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const handleSearch = useCallback(
    _.debounce((value) => {
      setQuery(value);
    }, 300),
    []
  );

  useEffect(() => { }, [value])

  return (
    <Select
      mode={multiple ? "multiple" : undefined}
      className='w-full'
      showSearch
      placeholder={placeholder}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      onChange={onChange}
      filterOption={(input: any, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={
        multiple
          ? options
            .filter((option: any) => value?.includes(option.value))
            .map((option: any) => option.value)
          : options.find((it: any) => it.value === value)?.value
      }
      disabled={disabled}
      allowClear
    />
  );
};

export default CustomSelectOptions;
